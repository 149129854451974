<template>
  <el-card style="min-height: 45rem">
    <el-row :gutter="40">
      <el-col :span="12">
        <el-form label-position="top">
          <el-form-item label="讲师简介">
            <y_editor v-model="form.detail"></y_editor>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12">
        <el-form>
          <el-form-item label="讲师名称">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="讲师头像">
            <y_upload_img v-model="form.pic"></y_upload_img>
          </el-form-item>
          <el-form-item label="选择用户">
            <y_choose_member width-input v-model="form.uid"></y_choose_member>
          </el-form-item>
          <el-form-item>
            <el-button @click="edit" type="primary">提交</el-button>
            <el-button @click="$router.back()">返回列表</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
// import y_editor from "@/components/editor/y_editor";
// import y_upload_img from "@/components/y_upload/y_upload_img";
// import y_choose_member from "@/components/y_choose_member";
import y_choose_member from "@/components/shop/y_choose_member";
import y_editor from "@/components/y-form/y-editor/y_editor";
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";

export default {
  components:{
    y_editor,
    y_upload_img,
    y_choose_member
  },
  name: "edit",
  data(){
    return{
      form:{
        uid:0,
        title:"",
        pic:"",
        detail:"",
      },
      edit_id:0,
    }
  },
  mounted() {
    if(this.$route.query.id){
      this.edit_id = parseInt(this.$route.query.id);
      this.load();
    }
  },
  methods:{
    load(){
      this.$u.api.shop.plugin.knowledge.teacherOne({id:this.edit_id}).then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$u.api.shop.plugin.knowledge.teacherEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.$router.back();
      })
    },
  },
}
</script>

<style scoped>

</style>